import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { REMOVE_PRODUCT_DETAILS, UPDATE_PRODUCT_RESET } from '../../constants/productConstants';
import { clearErrors, getProductDetails, updateProduct } from '../../actions/productAction';
import ImageIcon from '@mui/icons-material/Image';
import BackdropLoader from '../Layouts/BackdropLoader';
import { categories } from '../../utils/constants';
import MetaData from '../Layouts/MetaData';

const UpdateProduct = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();

    const { loading, product, error } = useSelector((state) => state.productDetails);
    const { loading: updateLoading, isUpdated, error: updateError } = useSelector((state) => state.product);

    const [highlights, setHighlights] = useState([]);
    const [highlightInput, setHighlightInput] = useState("");
    const [specs, setSpecs] = useState([]);
    const [specsInput, setSpecsInput] = useState({
        title: "",
        description: ""
    });

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [cuttedPrice, setCuttedPrice] = useState(0);
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState(""); 
    const [stock, setStock] = useState(0);
    const [warranty, setWarranty] = useState(0);
    const [brand, setBrand] = useState("");
    const [images, setImages] = useState([]);
        const [oldImages, setOldImages] = useState([]);
         const [imagesPreview, setImagesPreview] = useState([]);

    const [logo, setLogo] = useState("");
    const [logoPreview, setLogoPreview] = useState("");

    const handleSpecsChange = (e) => {
        setSpecsInput({ ...specsInput, [e.target.name]: e.target.value });
    };

    const addSpecs = () => {
        if (!specsInput.title.trim() || !specsInput.description.trim()) return;
        setSpecs([...specs, specsInput]);
        setSpecsInput({ title: "", description: "" });
    };

    const addHighlight = () => {
        if (!highlightInput.trim()) return;
        setHighlights([...highlights, highlightInput]);
        setHighlightInput("");
    };

    const deleteHighlight = (index) => {
        setHighlights(highlights.filter((h, i) => i !== index));
    };

    const deleteSpec = (index) => {
        setSpecs(specs.filter((s, i) => i !== index));
    };

    const handleLogoChange = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setLogoPreview(reader.result);
                setLogo(reader.result);
            }
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const handleProductImageChange = (e) => {
                const files = Array.from(e.target.files);
                setImages([]);
                setImagesPreview([]);
                files.forEach((file) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            setImagesPreview((oldData) => [...oldData, reader.result]);
                            setImages((oldData) => [...oldData, reader.result]);
                        }
                    };
                    reader.readAsDataURL(file);
                });
            };
    
            const newProductSubmitHandler = (e) => {
                e.preventDefault();
                const formData = new FormData();
                
                formData.set("name", name);
                formData.set("description", description);
                formData.set("price", price);
                formData.set("cuttedPrice", cuttedPrice);
                formData.set("category", category);
                formData.set("subcategory", subcategory);
                formData.set("stock", stock);
                
                // Conditionally add fields based on category
                if (category !== "Food" && category !== "Vegetables") {
                    formData.set("warranty", warranty);
                    formData.set("brandname", brand);
                    if (logo) {
                        formData.set("logo", logo);
                    }
                }
                
                images.forEach((image) => {
                    formData.append("images", image);
                });
                highlights.forEach((h) => {
                    formData.append("highlights", h);
                });
                specs.forEach((s) => {
                    formData.append("specifications", JSON.stringify(s));
                });
            
                dispatch(updateProduct(params.id, formData));
            };
            

    const productId = params.id;

    useEffect(() => {
        if (product && product._id !== productId) {
            dispatch(getProductDetails(productId));
        } else if (product) {
            setName(product.name || "");
            setDescription(product.description || "");
            setPrice(product.price || 0);
            setCuttedPrice(product.cuttedPrice || 0);
            setCategory(product.category || "");
            setSubcategory(product.subcategory || "");
            setStock(product.stock || 0);
            setWarranty(product.warranty || 0);
            setBrand(product.brand ? product.brand.name : "");
            setHighlights(product.highlights || []);
            setSpecs(product.specifications || []);
            setOldImages(product.images || []);
            setLogoPreview(product.brand ? product.brand.logo.url : "");
        }

        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (updateError) {
            enqueueSnackbar(updateError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isUpdated) {
            enqueueSnackbar("Product Updated Successfully", { variant: "success" });
            dispatch({ type: UPDATE_PRODUCT_RESET });
            dispatch({ type: REMOVE_PRODUCT_DETAILS });
            navigate('/admin/products');
        }
    }, [dispatch, error, updateError, isUpdated, productId, product, navigate, enqueueSnackbar]);

    const subcategories = categories.find(cat => cat.name === category)?.subcategories || [];

    return (
        <>
            <MetaData title="Admin: Update Product | Grafin" />

            {loading && <BackdropLoader />}
            {updateLoading && <BackdropLoader />}
            <form onSubmit={newProductSubmitHandler} encType="multipart/form-data" className="flex flex-col sm:flex-row bg-white rounded-lg shadow p-4" id="mainform">
                <div className="flex flex-col gap-3 m-2 sm:w-1/2">
                    <TextField
                        label="Name"
                        variant="outlined"
                        size="small"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        label="Description"
                        multiline
                        rows={3}
                        required
                        variant="outlined"
                        size="small"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className="flex justify-between">
                        <TextField
                            label="Price"
                            type="number"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            required
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <TextField
                            label="Cutted Price"
                            type="number"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            required
                            value={cuttedPrice}
                            onChange={(e) => setCuttedPrice(e.target.value)}
                        />
                    </div>
                    <div className="flex justify-between gap-4">
                        <TextField
                            label="Category"
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            {categories.map((cat, i) => (
                                <MenuItem value={cat.name} key={i}>
                                    {cat.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Subcategory"
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            value={subcategory}
                            onChange={(e) => setSubcategory(e.target.value)}
                        >
                            {subcategories.map((sub, i) => (
                                <MenuItem value={sub} key={i}>
                                    {sub}
                                </MenuItem>
                            ))}
                            {subcategories.length === 0 && (
                                <MenuItem value="">No Subcategories Available</MenuItem>
                            )}
                        </TextField>
                    </div>
                    <div className="flex justify-between gap-4">
                        <TextField
                            label="Stock"
                            type="number"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            required
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                        />
                        <TextField
                            label="Warranty"
                            type="number"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                        
                            value={warranty}
                            onChange={(e) => setWarranty(e.target.value)}
                        />
                    </div>
                    <TextField
                        label="Brand"
                        variant="outlined"
                        size="small"
                        
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                    />
                    <TextField
                        label="Highlight"
                        variant="outlined"
                        size="small"
                        value={highlightInput}
                        onChange={(e) => setHighlightInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                addHighlight();
                            }
                        }}
                    />
                    <div className="flex flex-wrap gap-2">
                        {highlights.map((highlight, i) => (
                            <div key={i} className="bg-gray-200 p-2 rounded-lg flex items-center gap-2">
                                <span>{highlight}</span>
                                <DeleteIcon onClick={() => deleteHighlight(i)} className="cursor-pointer" />
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            label="Specifications"
                            variant="outlined"
                            size="small"
                            name="title"
                            value={specsInput.title}
                            onChange={handleSpecsChange}
                            placeholder="Title"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    addSpecs();
                                }
                            }}
                        />
                        <TextField
                            label="Description"
                            variant="outlined"
                            size="small"
                            name="description"
                            value={specsInput.description}
                            onChange={handleSpecsChange}
                            placeholder="Description"
                            multiline
                            rows={3}
                        />
                        <div className="flex gap-2 mt-2">
                            {specs.map((spec, i) => (
                                <div key={i} className="bg-gray-200 p-2 rounded-lg flex flex-col">
                                    <span className="font-bold">{spec.title}</span>
                                    <span>{spec.description}</span>
                                    <DeleteIcon onClick={() => deleteSpec(i)} className="cursor-pointer" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

             <div className="flex flex-col gap-2">
                             <h2 className="font-medium">Product Images</h2>
                             <div className="flex gap-4 items-center">
                                 <input
                                    type="file"
                                    name="productImages"
                                    accept="image/*"
                                    onChange={handleProductImageChange}
                                    multiple
                                />
                                <div className="flex gap-2 flex-wrap">
                                    {imagesPreview.map((img, i) => (
                                        <img key={i} draggable="false" src={img} alt={`Product Preview ${i}`} className="w-20 h-20 object-cover" />
                                    ))}
                                </div>
                            </div>
                            {oldImages && oldImages.map((img, i) => (
                                <img key={i} draggable="false" src={img.url} alt={`Old Product Image ${i}`} className="w-20 h-20 object-cover" />
                            ))}
                        </div>

                        <div className="flex flex-col gap-2">
                            <h2 className="font-medium">Brand Logo</h2>
                            <input
                                type="file"
                                name="logo"
                                accept="image/*"
                                onChange={handleLogoChange}
                            />
                            {logoPreview && <img src={logoPreview} alt="Logo Preview" className="w-20 h-20 object-cover" />}
                        <button type="submit" className="py-2 px-4 bg-primary-blue text-white font-medium rounded hover:shadow-lg">
                        Update Product
                    </button>
                        </div>
                        
                
            </form>
        </>
    );
};

export default UpdateProduct;

   