import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import CartItem from './CartItem';
import EmptyCart from './EmptyCart';
import PriceSidebar from './PriceSidebar';
import SaveForLaterItem from './SaveForLaterItem';
import { fetchUserOrders } from '../../actions/orderAction'; // Make sure this action is available

const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [orderedProductIds, setOrderedProductIds] = useState([]);

    const { cartItems } = useSelector((state) => state.cart);
    const { saveForLaterItems } = useSelector((state) => state.saveForLater);
    const { orders } = useSelector((state) => state.order);

    useEffect(() => {
        // Fetch user orders
        dispatch(fetchUserOrders()).then(() => {
            // Extract ordered product IDs
            const orderedIds = (orders || [])
                .flatMap(order => order.orderItems.map(item => item.product._id));
            setOrderedProductIds(orderedIds);
        });
    }, [dispatch, orders]);

    // Filter out ordered items
    const availableItems = cartItems.filter(item => !orderedProductIds.includes(item.product));
    const recentOrderItems = cartItems.filter(item => orderedProductIds.includes(item.product));

    const placeOrderHandler = () => {
        navigate('/login?redirect=shipping');
    };

    return (
        <>
            <MetaData title="Shopping Cart | Grafin" />
            <main className="w-full mt-20">
                <div className="flex flex-col gap-5 sm:flex-row sm:w-11/12 sm:mx-auto sm:mb-7">

                    {/* My Cart Section */}
                    <div className="flex-1">
                        {/* My Cart Items Container */}
                        <div className="flex flex-col shadow bg-white">
                            <span className="font-medium text-lg px-4 py-4 border-b sm:px-8">
                                My Cart ({availableItems.length})
                            </span>

                            {/* Empty Cart Component */}
                            {availableItems.length === 0 && <EmptyCart />}

                            {/* Available Items List */}
                            {availableItems.map(item => (
                                <CartItem {...item} inCart={true} key={item.product} />
                            ))}

                            {/* Place Order Button */}
                            <div className="flex justify-end">
                                <button
                                    onClick={placeOrderHandler}
                                    disabled={availableItems.length < 1}
                                    className={`w-full sm:w-auto mx-2 sm:mx-6 my-4 py-3 font-medium text-white shadow rounded-sm ${availableItems.length < 1 ? "bg-primary-grey cursor-not-allowed" : "hover:bg-primary-orange-dark"}`}
                                    style={{ backgroundColor: '#427ef5', width: '150px' }}
                                >
                                    PLACE ORDER
                                </button>
                            </div>
                        </div>

                        {/* Recent Orders Section */}
                        <div className="flex flex-col mt-5 shadow bg-white">
                            <span className="font-medium text-lg px-4 py-4 border-b sm:px-8">
                                Recent Orders ({recentOrderItems.length})
                            </span>

                            {/* Recent Orders List */}
                            {recentOrderItems.length === 0 ? (
                                <div className="px-4 py-4 text-center">
                                    You have no recent orders.
                                </div>
                            ) : (
                                recentOrderItems.map(item => (
                                    <CartItem {...item} inCart={true} key={item.product} />
                                ))
                            )}
                        </div>

                        {/* Saved for Later Items Container */}
                        <div className="flex flex-col mt-5 shadow bg-white">
                            <span className="font-medium text-lg px-4 py-4 border-b sm:px-8">
                                Saved For Later ({saveForLaterItems.length})
                            </span>
                            {saveForLaterItems.map(item => (
                                <SaveForLaterItem {...item} key={item.product} />
                            ))}
                        </div>
                    </div>

                    {/* Price Sidebar */}
                    <PriceSidebar cartItems={availableItems} />
                </div>
            </main>
        </>
    );
};

export default Cart;
