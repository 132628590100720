import axios from "axios"
import { ADD_TO_CART, EMPTY_CART, REMOVE_FROM_CART, SAVE_SHIPPING_INFO } from "../constants/cartConstants";
import { fetchUserOrders } from '../actions/orderAction';
export const addItemsToCart = (id, quantity = 1) => async (dispatch, getState) => {
    try {
        // Fetch product details
        const { data } = await axios.get(`/api/v1/product/${id}`);
        
        // Fetch user orders
        await dispatch(fetchUserOrders());

        const { orders } = getState().order;

        // Ensure orders is defined and is an array
        const orderedProductIds = new Set((orders || []).flatMap(order => order.orderItems.map(item => item.product._id)));

        // Check if the product ID is already ordered
        if (orderedProductIds.has(data.product._id)) {
            console.warn("Product already ordered and cannot be added to the cart.");
            return; // Prevent adding the product to the cart
        }

        // Add to cart
        dispatch({
            type: ADD_TO_CART,
            payload: {
                product: data.product._id,
                name: data.product.name,
                seller: data.product.brand.name,
                price: data.product.price,
                cuttedPrice: data.product.cuttedPrice,
                image: data.product.images[0].url,
                stock: data.product.stock,
                quantity,
            },
        });

        // Save cart items to localStorage
        localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
    } catch (error) {
        console.error("Error adding item to cart: ", error);
    }
};
// remove cart item
export const removeItemsFromCart = (id) => async (dispatch, getState) => {

    dispatch({
        type: REMOVE_FROM_CART,
        payload: id,
    });

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

// empty cart
export const emptyCart = () => async (dispatch, getState) => {

    dispatch({ type: EMPTY_CART });

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

// save shipping info
export const saveShippingInfo = (data) => async (dispatch) => {

    dispatch({
        type: SAVE_SHIPPING_INFO,
        payload: data,
    });

    localStorage.setItem('shippingInfo', JSON.stringify(data));
}