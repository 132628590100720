import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import { getProducts, clearErrors } from '../../actions/productAction';
import Loader from '../Layouts/Loader';
import Product from './Product';
import Pagination from '@mui/material/Pagination';
import MetaData from '../Layouts/MetaData';
import { useSnackbar } from 'notistack';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'; // Import the arrow icon

// Categories array
export const catNav = [
    {
        name: "Electronics",
        subcategories: [
            "Mobile",
            "Laptops & computers",
            "Printers & scanners",
            "Television",
            "Airconditioners & washingmachines",
            "Audio Device",
            "Camera",
            "Videogames",
            "Home theater",
            "Car Electronics",
            "Office Equipment"
        ]
    },
    {
        name: " Mobile Accessories",
        subcategories: [
        
        "Tablets",
        "Mobile Covers  ",
         "Charger",
        "Smart Watches",
        "Headsets & earbuds",
        "Power Banks",
        "Cable",
        "Pendrive & Memorey Cards",
        "Bluetooth devices",
        "Mobile Holders",
]
    },
    {
        name: "Food",
        subcategories: [
            "Breakfast",
        "Lunch",
        "dinner", 
        "Snacks",
    "cooldrinks"
        ]
    },
    {
        name: "Vegetables",
        subcategories: [
            "Vegetables",
            "Fruits"
        ]
    },
   

    {
        name: "Groceries",
        subcategories: [
          "Rice",
        "Flour",
       "Dairy Products",
       "tea & coffee powder ",
       "Masala & Spices",
       "Oil",
       "Dry Fruits",
       "Nuts",
       "Bakery Products",
       "Baby Care",
       "Packaged Snacks",
       "Home Cleaner",
        ]
    },
    {
        name: "Fashion",
        subcategories: [
        "Boys t-shirts&pants",
        "Girls t-shirts&pants",
        "Mens Accessories",
        "Womens Accessories",
        "Kids Accessories",
        "Athletic Wear",
        "Foot Wear",
        "Sportshoes&sneackers",
        "Spring&summer",
        "Tops&dresses",
        "Jwellery",
        "Handbags",
        "Watches"
        ]
    },
    {
        name: "Home Appliances",
        subcategories: [
        "Kitchen & dining",
        "Furniture",
        "Weather Control Appliances",
        "  Electrical & Lighting",
        "Personal Care Appliances",
        "Water purification",
        "Cleaning Appliances",
        "Home Security Appliances",
        "Textile Care Appliances",
        "Refrigeration Appliances"
        ]
    },
    {
        name: "Opticals",
        subcategories: [
            
        "Eyewear",
 "Contact Lenses",
 "lens Care Products",
 "Optical Accessories",
 "Optical Instruments",
 "Vision Care Products",
 "Optical Devices",
 "Optometric Tools",    
        ]
    },
 
    {
        name: "Toys",
        subcategories: [
        "Trycycles",
        "Boost & plasters",
        "Play Station",
        "Card & Boardgames",
        "Action Figure",
        "Dolls",
        "Animal Toys",
        "Educational Toys",
        "Outdoor games",
        "Puzzles",
        "Remote Control Toys",
        "Musical Toys",
        "Sensore Toys",
        ]
    },
  
];

// Subcategories mapping for each category
const subcategoryMapping = {
    "Electronics": [ 
        "Mobile",
        "Laptops & computers",
        "Printers & scanners",
        "Television",
        "Airconditioners & washingmachines",
        "Audio Device",
        "Camera",
        "Videogames",
        "Home theater",
        "Car Electronics",
        "Office Equipment"],

    " Mobile Accessories":[ 
        
        "Tablets",
        "Mobile Covers  ",
         "Charger",
        "Smart Watches",
        "Headsets & earbuds",
        "Power Banks",
        "Cable",
        "Pendrive & Memorey Cards",
        "Bluetooth devices",
        "Mobile Holders",
        
        ],
    "Food":[
        "Breakfast",
        "Lunch",
        "dinner", 
        "Snacks",
    "cooldrinks"],
     
    "Vegetables": [
        "Vegetables",
        "Fruits"
    ],
    "Groceries":[
        "Rice",
        "Flour",
       "Dairy Products",
       "tea & coffee powder ",
       "Masala & Spices",
       "Oil",
       "Dry Fruits",
       "Nuts",
       "Bakery Products",
       "Baby Care",
       "Packaged Snacks",
       "Home Cleaner",
    ],
    "Fashion": [ 
        "Boys t-shirts&pants",
        "Girls t-shirts&pants",
        "Mens Accessories",
        "Womens Accessories",
        "Kids Accessories",
        "Athletic Wear",
        "Foot Wear",
        "Sportshoes&sneackers",
        "Spring&summer",
        "Tops&dresses",
        "Jwellery",
        "Handbags",
        "Watches"
    ],
    "Home Appliances":[
        "Kitchen & dining",
        "Furniture",
        "Weather Control Appliances",
        "  Electrical & Lighting",
        "Personal Care Appliances",
        "Water purification",
        "Cleaning Appliances",
        "Home Security Appliances",
        "Textile Care Appliances",
        "Refrigeration Appliances"

    ],
    
    "Opticals":[
        "Eyewear",
 "Contact Lenses",
 "lens Care Products",
 "Optical Accessories",
 "Optical Instruments",
 "Vision Care Products",
 "Optical Devices",
 "Optometric Tools",
    ],
    
    "Toys":[
        "Trycycles",
        "Boost & plasters",
        "Play Station",
        "Card & Boardgames",
        "Action Figure",
        "Dolls",
        "Animal Toys",
        "Educational Toys",
        "Outdoor games",
        "Puzzles",
        "Remote Control Toys",
        "Musical Toys",
        "Sensore Toys",
    ],
    

    // Add more subcategories as needed...
};

// Define subcategory menu items
const getSubcategoryMenu = (subcategories, handleSubcategoryClick) => {
    return subcategories.map((subcat, i) => (
        <Link
            key={i}
            to="#"
            className="flex items-center justify-between p-2 bold-text text-white bg-black rounded-md shadow-sm hover:bg-gray-800 cursor-pointer"
            onClick={() => handleSubcategoryClick(subcat)}
        >
            <span>{subcat}</span>
            <ArrowForwardIos fontSize="small" className="ml-2 text-white" /> {/* Using small size */}
        </Link>
    ));
};

const Products = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [price, setPrice] = useState([0, 200000]);
    const [category, setCategory] = useState(location.search ? location.search.split('=')[1] : "");
    const [ratings, setRatings] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [availability, setAvailability] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [hoveredCategory, setHoveredCategory] = useState(null); // Track hovered category
    const [subcategories, setSubcategories] = useState([]); // Store related subcategories
    const [selectedSubcategory, setSelectedSubcategory] = useState(null); // Selected subcategory


const [isSubcategoryMenuOpen, setIsSubcategoryMenuOpen] = useState(false);

const toggleSubcategoryMenu = () => {
    setIsSubcategoryMenuOpen(prev => !prev);
};
    const { products, loading, error, productsCount, resultPerPage, filteredProductsCount } = useSelector((state) => state.products);
    const keyword = params.keyword;

    const handleCategoryHover = (cat) => {
        setHoveredCategory(cat);
        setSubcategories(subcategoryMapping[cat] || []); // Get subcategories for the hovered category
    };

    const handleCategoryClick = (cat) => {
        if (hoveredCategory === cat) {
            // If the same category is clicked again, close it
            setHoveredCategory(null);
            setSubcategories([]);  // Clear subcategories
        } else {
            // Set the clicked category and show subcategories
            setHoveredCategory(cat);
            setSubcategories(subcategoryMapping[cat] || []);  // Show subcategories for the clicked category
        }
        setCategory(cat);  // Keep this if you want to change the main category
        navigate(`/products?category=${cat}`);
        setSelectedSubcategory(null);  // Reset subcategory on category click
    };
    

    const handleSubcategoryClick = (subcat) => {
        setSelectedSubcategory(subcat);
        // Fetch products for the selected subcategory
        dispatch(getProducts(keyword, category, price, ratings, currentPage, discount, availability, subcat));
    };

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            dispatch(clearErrors());
        }
        dispatch(getProducts(keyword, category, price, ratings, currentPage, discount, availability, selectedSubcategory));
    }, [dispatch, keyword, category, price, ratings, currentPage, discount, availability, error, enqueueSnackbar, selectedSubcategory]);

    return (
        <>
            <MetaData title="All Products | Grafin" />
       <section
    className="bg-white mt-10 mb-4 min-w-full px-4 sm:px-12 py-1 shadow overflow-hidden"
    style={{ overflowX: window.innerWidth < 640 ? 'auto' : 'hidden' }} // Inline style for conditional overflow
>
    <div
        className="flex items-center justify-between mt-4"
        style={{
            display: 'flex',
            overflowX: window.innerWidth < 640 ? 'auto' : 'hidden', // Inline style for conditional overflow
            whiteSpace: 'nowrap' // Ensure items are in a single line
        }}
    >
        {catNav.map((item, i) => (
            <div
                key={i}
                className="flex flex-col gap-1 items-center p-2 group cursor-pointer"
                onClick={() => handleCategoryClick(item.name)}
                style={{
                    display: 'inline-block', // Ensure items are inline
                    marginRight: '8px' // Optional: add some spacing between items
                }}
            >
                <span className="text-sm text-gray-800 font-medium group-hover:text-primary-blue">
                    {item.name}
                </span>
            </div>
        ))}
    </div>
</section>
            {/* Categories navigation */}
     


            <main className="w-full mt-5 sm:mt-0 flex flex-col sm:flex-row">
    {/* Left side: Subcategory nav */}
    {(hoveredCategory && subcategories.length > 0 && (isSubcategoryMenuOpen || window.innerWidth >= 310)) && (
        <aside className="w-full sm:w-1/6 p-4 bg-black shadow-lg mb-4 sm:mb-0"> {/* Reduced width */}
            <h3 className="text-lg font-semibold mb-4 text-yellow-400 bg-black p-2 rounded-md">Subcategories</h3>
            <nav className="flex flex-col gap-2 bold-text">
                {getSubcategoryMenu(subcategories, handleSubcategoryClick)}
            </nav>
        </aside>
    )}

    {/* Main content: Products */}
    <div className={`flex-1 ${hoveredCategory || isSubcategoryMenuOpen ? 'ml-0 sm:ml-4' : ''}`}>
        {loading ? (
            <Loader />
        ) : (
            <>
                {/* No results found */}
                {products?.length === 0 && (
                    <div className="flex flex-col items-center justify-center gap-3 bg-white shadow-sm rounded-sm p-6 sm:p-16">
                        <img
                            draggable="false"
                            className="w-1/2 h-44 object-contain"
                            src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/error-no-search-results_2353c5.png"
                            alt="Search Not Found"
                        />
                        <h1 className="text-2xl font-medium text-gray-900">Sorry, no results found!</h1>
                        <p className="text-xl text-center text-primary-grey">
                            Please check the spelling or try searching for something else
                        </p>
                    </div>
                )}

                {/* Products grid */}
                <div className="flex flex-col gap-2 pb-4 justify-center items-center w-full overflow-hidden bg-white">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full place-content-start overflow-hidden pb-4 border-b">
                        {products?.map((product) => (
                            <Product {...product} key={product._id} />
                        ))}
                    </div>

                    {/* Pagination if products exceed result per page */}
                    {filteredProductsCount > resultPerPage && (
                        <Pagination
                            count={Number(((filteredProductsCount + 6) / resultPerPage).toFixed())}
                            page={currentPage}
                            onChange={(e, val) => setCurrentPage(val)}
                            color="primary"
                        />
                    )}
                </div>
            </>
        )}
    </div>
</main>
        </>
    );
};

export default Products;
